import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "dashboard" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {

    // Pages
    // Rổ hàng
    path: "/cart",
    name: "cart",
    meta: { title: "Cart", authRequired: true },
    component: () => import("../views/pages/cart"),
  },
  {
    // Dashboard
    path: "/",
    name: "dashboard",
    meta: { title: "DashBoard", authRequired: true },
    component: () => import("../views/pages/dashboard"),
  },

  // Book Phòng
  {
    path: "/Book/List",
    name: "bookList",
    meta: { title: "Book List", authRequired: true },
    component: () => import("../views/book/book-list"),
  },
  {
    path: "/Book/Detail",
    name: "bookDetail",
    meta: { title: "Book Detail", authRequired: true },
    component: () => import("../views/book/book-detail"),
  },
  {
    path: "/Book/History",
    name: "bookHistory",
    meta: { title: "Book History", authRequired: true },
    component: () => import("../views/book/book-history"),
  },

  // Booking
  {
    path: "/Booking/List",
    name: "bookingList",
    meta: { title: "Booking List", authRequired: true },
    component: () => import("../views/booking/booking-list"),
  },
  {
    path: "/Booking/History",
    name: "bookingHistory",
    meta: { title: "Booking History", authRequired: true },
    component: () => import("../views/booking/booking-history"),
  },
  {
    path: "/Booking/Detail",
    name: "bookingDetail",
    meta: { title: "Booking Detail", authRequired: true },
    component: () => import("../views/booking/booking-detail"),
  },

  // Khách hàng
  {
    path: "/Customer/List",
    name: "customerList",
    meta: { title: "Customer List", authRequired: true },
    component: () => import("../views/customer/customer-list"),
  },
  {
    path: "/Customer/Detail",
    name: "customerDetail",
    meta: { title: "Customer Detail", authRequired: true },
    component: () => import("../views/customer/customer-detail"),
  },

  // Thông tin cá nhân
  {
    path: "/Profile/Setting",
    name: "profileSetting",
    meta: { title: "Profile Setting", authRequired: true },
    component: () => import("../views/profile/profile-setting"),
  },
  {
    path: "/Profile/Detail",
    name: "profileDetail",
    meta: { title: "Profile Detail", authRequired: true },
    component: () => import("../views/profile/profile-detail"),
  },

  //Giao dịch
  {
    path: "/Transaction/List",
    name: "transactionList",
    meta: { title: "Transaction List", authRequired: true },
    component: () => import("../views/transaction/transaction-list"),
  },
  {
    path: "/Transaction/Detail",
    name: "transactionDetail",
    meta: { title: "Transaction Detail", authRequired: true },
    component: () => import("../views/transaction/transaction-detail"),
  },
];